import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import {
  userColumns,
  accountColumns,
  newsColumns,
  newsletterColumns,
  contactColumns,
  reportColumns,
  markerColumns,
  suggestionColumns,
  projectColumns,
  mdaColumns,
  budgetColumns,
  budgetPerformanceColumns,
  lgaColumns,
  wardColumns,
  faqColumns,
  fileColumns,
  sectorColumns,
  projectFileColumns,
} from "../../datatablesource";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";
import { BiSearch } from "react-icons/bi";
import Modal from "../modal/Modal";
import BudgetModal from "../modal/BudgetModal";

const Datatable = () => {
  const [tdata, setData] = useState([]);
  const location = useLocation();
  const docName = location.pathname.split("/")[1];
  const [pageSize, setPageSize] = useState(20);
  const [openModal, setOpenModal] = useState(false);
  const [query, setQuery] = useState("");
  let keys;

  if (docName === "project") {
    keys = [
      "title",
      "category",
      "sector",
      "sector",
      "lga",
      "ward",
      "mda",
      "contractor",
    ];
  } else if (docName === "admins") {
    keys = ["name", "email", "phone", "status"];
  } else if (docName === "users") {
    keys = ["name", "email"];
  } else if (docName === "news") {
    keys = ["header", "message"];
  } else if (docName === "faqs") {
    keys = ["question", "answer"];
  } else if (docName === "mdas") {
    keys = ["name", "code"];
  } else if (docName === "suggestion") {
    keys = ["ward", "community", "comment"];
  } else if (docName === "report") {
    keys = ["comment", "status"];
  } else if (docName === "newsletter") {
    keys = ["email"];
  } else if (docName === "contact") {
    keys = ["name", "email", "phone", "subject", "message"];
  } else if (docName === "budget") {
    keys = [
      "year",
      "programme",
      "description",
      "economic",
      "function",
      "location",
    ];
  } else if (docName === "budgetPerformance") {
    keys = ["month"];
  } else if (docName === "budgetFile") {
    keys = ["fiscal"];
  } else if (docName === "grant") {
    keys = ["fiscal"];
  } else if (docName === "partnersProject") {
    keys = ["partner"];
  } else {
    keys = ["name"];
  }
  const search = (fdata) => {
    return fdata.filter((item) =>
      keys.some((key) => item[key] && item[key].toLowerCase().includes(query))
    );
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await userRequest.get(docName);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (query.length === 0 || query.length > 2) getData();
  }, [docName, query]);

  const handleDelete = async (id) => {
    try {
      await userRequest.delete(docName + "/" + id);
      setData(tdata.filter((item) => item._id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsubscribe = async (id, statu) => {
    try {
      await userRequest.patch(docName + "/unsubscribe/" + id, {
        status: !statu,
      });
      // setData(tdata.filter((item) => item._id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  const viewColumn = [
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={"/" + docName + "/" + params.row._id}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={"/" + docName + "/" + params.row._id}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() =>
                window.confirm(
                  "Are you sure you want to delete row with id " +
                    params.row._id +
                    "?"
                ) && handleDelete(params.row._id)
              }
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  const unsubActionColumn = [
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className={
                params.row.status ? "unsubscribeButton" : "subscribeButton"
              }
              onClick={() =>
                window.confirm(
                  "Are you sure you want to unsubscribe email with id " +
                    params.row._id +
                    "?"
                ) && handleUnsubscribe(params.row._id, params.row.status)
              }
            >
              {params.row.status ? "unsubscribe" : "subscribe"}
            </div>
            <div
              className="deleteButton"
              onClick={() =>
                window.confirm(
                  "Are you sure you want to delete row with id " +
                    params.row._id +
                    "?"
                ) && handleDelete(params.row._id)
              }
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  // const numb = [
  //   {
  //     name: "",
  //     label: "",
  //     options: {
  //       filter: false,
  //       customBodyRender: (value, tableMeta, update) => {
  //         let rowIndex = Number(tableMeta.rowIndex) + 1;
  //         return <span>{rowIndex}</span>;
  //       },
  //     },
  //   },
  // ];
  return (
    <div className="datatable">
      {docName === "admins" ||
      docName === "news" ||
      docName === "sector" ||
      docName === "mdas" ||
      docName === "faqs" ||
      docName === "lgas" ||
      docName === "budgetFile" ||
      docName === "grant" ||
      docName === "partnersProject" ||
      docName === "marker" ||
      docName === "project" ? (
        <div className="datatableTitle">
          ADD {docName.toLocaleUpperCase()}
          <Link to={"/" + docName + "/new"} className="link">
            Add New
          </Link>
        </div>
      ) : docName === "budgetPerformance" ? (
        <>
          <div className="datatableTitle">
            Add Budget report
            <button className="link" onClick={() => setOpenModal(!openModal)}>
              Add Report
            </button>
          </div>
          {openModal && <Modal closeModal={setOpenModal} />}
        </>
      ) : docName === "budget" ? (
        <>
          <div className="datatableTitle">
            ADD NEW FILE
            <button className="link" onClick={() => setOpenModal(!openModal)}>
              Add Budget
            </button>
          </div>
          {openModal && <BudgetModal closeModal={setOpenModal} />}
        </>
      ) : (
        <></>
      )}
      <div className="topBar">
        {/* <h2>Projects in Adamawa State</h2> */}
        <div className="search">
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
          <BiSearch />
        </div>
      </div>
      <DataGrid
        className="datagrid"
        autoHeight
        rows={search(tdata)}
        getRowId={(row) => row._id}
        columns={
          docName === "users"
            ? userColumns.concat(viewColumn)
            : docName === "admins"
            ? accountColumns.concat(viewColumn)
            : docName === "news"
            ? newsColumns.concat(actionColumn)
            : docName === "mdas"
            ? mdaColumns.concat(actionColumn)
            : docName === "sector"
            ? sectorColumns.concat(actionColumn)
            : docName === "lgas"
            ? lgaColumns.concat(actionColumn)
            : docName === "wards"
            ? wardColumns.concat(actionColumn)
            : docName === "faqs"
            ? faqColumns.concat(actionColumn)
            : docName === "budget"
            ? budgetColumns.concat(actionColumn)
            : docName === "budgetPerformance"
            ? budgetPerformanceColumns.concat(actionColumn)
            : docName === "marker"
            ? markerColumns.concat(actionColumn)
            : docName === "newsletter"
            ? newsletterColumns.concat(unsubActionColumn)
            : docName === "suggestion"
            ? suggestionColumns.concat(actionColumn)
            : docName === "project"
            ? projectColumns.concat(actionColumn)
            : docName === "report"
            ? reportColumns.concat(actionColumn)
            : docName === "budgetFile"
            ? fileColumns.concat(actionColumn)
            : docName === "grant"
            ? fileColumns.concat(actionColumn)
            : docName === "partnersProject"
            ? projectFileColumns.concat(actionColumn)
            : docName === "contact"
            ? contactColumns.concat(actionColumn)
            : reportColumns.concat(actionColumn)
        }
        pageSize={pageSize}
        rowsPerPageOptions={[20, 40, 60, 80, 100]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}

        // checkboxSelection
      />
    </div>
  );
};

export default Datatable;
