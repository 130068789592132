import { useState, useEffect } from "react";
import { fetchMenus, createMenu, updateMenu } from "../../services/menuService";

const MenuForm = ({ editingMenu, setEditingMenu, refreshMenus }) => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [parentId, setParentId] = useState(null);
  const [menuType, setMenuType] = useState("link");
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    const loadMenus = async () => setMenus(await fetchMenus());
    loadMenus();
  }, []);

  useEffect(() => {
    if (editingMenu) {
      setTitle(editingMenu.title);
      setUrl(editingMenu.url);
      setParentId(editingMenu.parent_id);
      setMenuType(editingMenu.type);
    }
  }, [editingMenu]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const menuData = {
      title,
      url,
      parent_id: parentId || null,
      type: menuType,
    };

    try {
      if (editingMenu) {
        await updateMenu(editingMenu._id, menuData);
      } else {
        await createMenu(menuData);
      }

      // Clear the form fields
      setTitle("");
      setUrl("");
      setParentId(null);
      setMenuType("link");

      // Reset editing state
      setEditingMenu(null);

      // Refresh menu list
      refreshMenus();

      alert("Menu saved successfully!"); // Temporary feedback message
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while saving the menu.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Slug (e.g. page/about-us or about-us)"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        required
      />
      <select
        value={parentId || ""}
        onChange={(e) => setParentId(e.target.value)}
      >
        <option value="">No Parent</option>
        {menus.map((menu) => (
          <option key={menu._id} value={menu._id}>
            {menu.title}
          </option>
        ))}
      </select>
      <select value={menuType} onChange={(e) => setMenuType(e.target.value)}>
        <option value="link">Link</option>
        <option value="dropdown">Dropdown</option>
      </select>
      <button type="submit">{editingMenu ? "Update" : "Add"} Menu</button>
    </form>
  );
};

export default MenuForm;
