import { useParams } from "react-router-dom";
import "./resetPassword.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";

const ResetPassword = ({ title }) => {
  // Get user ID from URL parameters
  const { userId } = useParams();

  const [input, setInput] = useState({
    newPassword: "",
    confirm: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    setInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(input));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (
      Object.keys(formErrors).length === 0 &&
      isSubmit &&
      input.newPassword !== "" &&
      input.confirm !== ""
    ) {
      userRequest
        .post(`/admins/resetPassword/${userId}`, input)
        .then((res) => {
          setInput({
            newPassword: "",
            confirm: "",
          });
          setTimeout(() => {
            setIsSubmit(false);
          }, 10000);
        })
        .catch((error) => {
          setFormErrors({
            form: error.response?.data?.msg || "An error occurred",
          });
        });
    }
  }, [formErrors, input, isSubmit, userId]);

  const validate = (values) => {
    const errors = {};
    if (!values.newPassword) {
      errors.newPassword = "Password is required!";
    }
    if (!values.confirm) {
      errors.confirm = "Confirm password is required!";
    }
    if (values.newPassword !== values.confirm) {
      errors.confirm = "Passwords must match!";
    }
    return errors;
  };

  return (
    <div className="edit">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left"></div>
          <div className="right">
            {Object.keys(formErrors).length === 0 && isSubmit && (
              <div className="ui message success">
                Reset password was successful.
              </div>
            )}
            <i style={{ color: "red" }}>{formErrors.form}</i>
            <form>
              <div className="formInput">
                <label>New Password</label>
                <input
                  type="password"
                  name="newPassword"
                  value={input.newPassword}
                  className={
                    formErrors.newPassword ? "err" : isSubmit ? "crt" : ""
                  }
                  placeholder="New Password"
                  onChange={handleChange}
                />
                <i>{formErrors.newPassword}</i>
              </div>
              <div className="formInput">
                <label>Confirm New Password</label>
                <input
                  type="password"
                  name="confirm"
                  placeholder="Confirm Password"
                  className={formErrors.confirm ? "err" : isSubmit ? "crt" : ""}
                  value={input.confirm}
                  onChange={handleChange}
                />
                <i>{formErrors.confirm}</i>
              </div>
              <div className="submit-btn">
                <button onClick={handleSubmit}>Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
