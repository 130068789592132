import "./profile.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";

const Profile = () => {
  let id =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.admin.id
      : false;

  const [uStats, setUStats] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userRequest.get(`admins/${id}`);
        setUStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, [id]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonu">
              <Link to={"/admins/changePassword/"}>Change Password</Link>
            </div>
            <h1 className="titlen">Profile</h1>
            {
              <div className="item">
                <img
                  src={uStats.img || require("../../assets/images/staff.png")}
                  alt=""
                  className="itemImg"
                />
                <div className="details">
                  <h1 className="itemTitle">{uStats.name}</h1>
                  <div className="detailItem">
                    <span className="itemKeyu">Email:</span>
                    <span className="itemValue">{uStats.email}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Username:</span>
                    <span className="itemValue">{uStats.username}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Access Right:</span>
                    <span className="itemValue">
                      {uStats.isAdmin ? "Admin" : "Staff"}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">M.D.A:</span>
                    <span className="itemValue">{uStats.mda}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Department:</span>
                    <span className="itemValue">{uStats.department}</span>
                  </div>
                  {/* <div className="detailItem">
                    <span className="itemKeyu">Office:</span>
                    <span className="itemValue">{uStats.office}</span>
                  </div> */}
                  {/* <div className="detailItem">
                    <span className="itemKeyu">Country:</span>
                    <span className="itemValue">Nigeria</span>
                  </div> */}
                </div>
              </div>
            }
          </div>
          {/* <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div> */}
        </div>
        <div className="bottom"></div>
      </div>
    </div>
  );
};

export default Profile;
