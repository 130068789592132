import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { publicRequest } from "../../requestMethods"; // Import publicRequest
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
} from "@mui/material";
import "./pages.scss";

const Pages = () => {
  const [pages, setPages] = useState([]);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [content, setContent] = useState("");
  const [status, setStatus] = useState("draft");
  const [editingPage, setEditingPage] = useState(null);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const res = await publicRequest.get("/pages");
      setPages(res.data);
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const pageData = { title, slug, content, status };

    try {
      if (editingPage) {
        await publicRequest.patch(`/pages/${editingPage._id}`, pageData);
      } else {
        await publicRequest.post("/pages", pageData);
      }

      setTitle("");
      setSlug("");
      setContent("");
      setStatus("draft");
      setEditingPage(null);
      fetchPages();
    } catch (error) {
      console.error("Error saving page:", error);
    }
  };

  const handleEdit = (page) => {
    setEditingPage(page);
    setTitle(page.title);
    setSlug(page.slug);
    setContent(page.content);
    setStatus(page.status);
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this page?")) return;

    try {
      await publicRequest.delete(`/pages/${id}`);
      fetchPages();
    } catch (error) {
      console.error("Error deleting page:", error);
    }
  };

  // Function to handle both image and media uploads
  const uploadMediaOrImage = (blobInfo, progress, type) => {
    const url =
      type === "image"
        ? "https://api.admethub.ng/api/v1/file/tinymce"
        : "https://api.admethub.ng/api/v1/file/tinymce/media";

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);

      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const percent = (e.loaded / e.total) * 100;
          progress(percent);
          console.log(`Upload Progress: ${percent.toFixed(2)}%`);
        }
      };

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          console.error("Upload failed with status:", xhr.status);
          reject("HTTP Error: " + xhr.status);
          return;
        }

        try {
          const json = JSON.parse(xhr.responseText);
          if (!json || typeof json.location !== "string") {
            console.error("Invalid JSON response:", xhr.responseText);
            reject("Invalid JSON response.");
            return;
          }

          console.log("Media uploaded successfully. URL:", json.location);
          resolve(json.location);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          reject("Error parsing JSON response.");
        }
      };

      xhr.onerror = () => {
        console.error("Media upload failed due to network error.");
        reject("Media upload failed due to network error.");
      };

      xhr.send(formData);
    });
  };

  return (
    <div className="pages">
      <Sidebar />
      <div className="pagesContainer">
        <Navbar />
        <h1 className="title">Manage Pages</h1>

        <div className="formContainer">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Page Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Slug (e.g. about-us)"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              required
            />

            {/* TinyMCE Rich Text Editor */}
            <Editor
              apiKey="p88zmbryzdfx1khrp5vixcsyew9ynn6waeuf2ifzij648zna" // Replace with your TinyMCE API key
              value={content}
              init={{
                height: 400,
                plugins: [
                  "advlist",
                  "autolink",
                  "link",
                  "image",
                  "media", // Include media plugin
                  "lists",
                  "charmap",
                  "preview",
                  "anchor",
                  "pagebreak",
                  "searchreplace",
                  "wordcount",
                  "visualblocks",
                  "visualchars",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "table",
                  "emoticons",
                  "help",
                ],
                toolbar:
                  "undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | " +
                  "bullist numlist outdent indent | link image media | print preview media fullscreen | " +
                  "forecolor backcolor emoticons | help",
                images_upload_handler: (blobInfo, progress) => {
                  return uploadMediaOrImage(blobInfo, progress, "image");
                },
                media_upload_handler: (blobInfo, progress) => {
                  return uploadMediaOrImage(blobInfo, progress, "media"); // Media handler
                },
                file_picker_types: "image,media", // This enables media file picker
                file_picker_callback: (callback, value, meta) => {
                  const input = document.createElement("input");
                  input.setAttribute("type", "file");
                  input.setAttribute(
                    "accept",
                    meta.filetype === "image" ? "image/*" : "video/*,audio/*"
                  );
                  input.click();

                  input.onchange = () => {
                    const file = input.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      // For image files
                      if (meta.filetype === "image") {
                        callback(reader.result, { alt: file.name });
                      }
                      // For media files (video/audio)
                      else if (meta.filetype === "media") {
                        const mediaUrl = reader.result;
                        callback(mediaUrl, { source: mediaUrl });
                      }
                    };
                    reader.readAsDataURL(file);
                  };
                },
              }}
              onEditorChange={(content) => setContent(content)}
            />

            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="draft">Draft</option>
              <option value="published">Published</option>
            </select>
            <button type="submit">
              {editingPage ? "Update Page" : "Create Page"}
            </button>
          </form>
        </div>

        <div className="tableContainer">
          <h2>Existing Pages</h2>
          <TableContainer component={Paper} className="tableContainer">
            <Table sx={{ minWidth: 650 }}>
              <TableHead className="tableHead">
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Slug</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pages
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((page) => (
                    <TableRow key={page._id}>
                      <TableCell>{page.title}</TableCell>
                      <TableCell>{page.slug}</TableCell>
                      <TableCell>{page.status}</TableCell>
                      <TableCell>
                        <Button onClick={() => handleEdit(page)}>Edit</Button>
                        <Button onClick={() => handleDelete(page._id)}>
                          Delete
                        </Button>
                        <Link to={`/pages/${page._id}`} className="viewButton">
                          View
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={pages.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={(event, newPage) => setPage(newPage)}
              rowsPerPageOptions={[10]}
            />
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Pages;
