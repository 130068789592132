import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import Error from "./pages/404/Error";
import Unauthorized from "./pages/401/Unauthorized";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  newsInputs,
  userInputs,
  markersInputs,
  projectInputs,
  mdaInputs,
  faqInputs,
  lgaInputs,
  wardInputs,
  fileInputs,
  fiscalInputs,
  partnerInputs,
  sectorInputs,
  grantFileInputs,
} from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import SingleNews from "./pages/singleNews/SingleNews";
import SingleMarker from "./pages/singleMarker/SingleMarker";
import SingleAccount from "./pages/singleAccount/SingleAccount";
import SingleReport from "./pages/singleReport/SingleReport";
import SingleSuggestion from "./pages/singleSuggestion/SingleSuggestion";
import SingleContact from "./pages/singleContact/SingleContact";
import SingleMda from "./pages/singleMda/SingleMda";
import Maps from "./pages/map/Maps";
import RequireAuth from "./pages/ProtectedRoutes";
import SingleProject from "./pages/singleProject/SingleProject";
import Modal from "./components/modal/Modal";
import ReportList from "./pages/list/ReportList";
import BudgetModal from "./components/modal/BudgetModal";
import EditAccount from "./pages/edit/EditAccount";
import EditMda from "./pages/edit/EditMda";
import EditProject from "./pages/edit/EditProject";
import EditNews from "./pages/edit/EditNews";
import SingleBudget from "./pages/singleBudget/SingleBudget";
import SingleComment from "./pages/singleNewsComment/SingleComment";
import SingleFaq from "./pages/singleFaq/SingleFaq";
import Profile from "./pages/profile/Profile";
import EditUser from "./pages/edit/EditUser";
import ChangePassword from "./pages/change/ChangePassword";
import ResetPassword from "./pages/change/ResetPassword";
import EditFaq from "./pages/edit/EditFaq";
import SingleLga from "./pages/singleLga/SingleLga";
import EditLga from "./pages/edit/EditLga";
import EditWard from "./pages/edit/EditWard";
import SingleYear from "./pages/singleYear/SingleYear";
import NewPublicFile from "./pages/new/NewPublicFile";
import SingleSector from "./pages/singleSector/SingleSector";
import SinglePartner from "./pages/singlePartner/SinglePartner";
import NewPartnerProject from "./pages/new/NewPartnerProject";
import SinglePeriod from "./pages/singleYear/SinglePeriod";
import NewGrantFile from "./pages/new/NewGrantFile";
import Pages from "./pages/pages/pages";
import PageView from "./pages/pages/pageView";
import Menu from "./pages/menu/Menu";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  const admin =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.admin.isAdmin
      : false;

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={admin ? <Home /> : <Login />} />
            <Route path="login" element={<Login />} />
            <Route path="map" element={<Maps />} />
            <Route
              path="dashboard"
              element={
                <RequireAuth redirectTo="/unauthenticated">
                  <Home />
                </RequireAuth>
              }
            />
            <Route path="users">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route
                path=":userId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <Single />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={userInputs} title="Add New User" />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:userId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditAccount title="Edit Account" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="news">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":newsId" element={<SingleNews />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={newsInputs} title="Add News" />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:newsId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditNews title="Edit News" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="marker">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":markerId" element={<SingleMarker />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={markersInputs} title="Add Marker" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="budgetFile">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":fileId" element={<SingleYear />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={fiscalInputs} title="Add Fiscal Year" />
                  </RequireAuth>
                }
              />
              <Route
                path="file/:fileId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <NewPublicFile
                      inputs={fileInputs}
                      title="Add Budget File"
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:fileId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditFaq title="Edit Budget File" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="grant">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":fileId" element={<SinglePeriod />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={fiscalInputs} title="Add Fiscal Year" />
                  </RequireAuth>
                }
              />
              <Route
                path="file/:fileId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <NewGrantFile
                      inputs={grantFileInputs}
                      title="Add Grant File"
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:fileId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditFaq title="Edit Grant File" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="faqs">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":faqId" element={<SingleFaq />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={faqInputs} title="Add FAQ" />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:faqId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditFaq title="Edit FAQ" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="lgas">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":lgaId" element={<SingleLga />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={lgaInputs} title="Add L.G.A" />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:lgaId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditLga title="Edit L.G.A" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="wards">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":wardId" element={<SingleMarker />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={wardInputs} title="Add Ward" />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:wardId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditWard title="Edit Ward" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="project">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":projectId" element={<SingleProject />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={projectInputs} title="Add Project" />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:projectId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditProject title="Edit Project" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="partnersProject">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":fileId" element={<SinglePartner />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={partnerInputs} title="Add Partner Name" />
                  </RequireAuth>
                }
              />
              <Route
                path="file/:fileId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <NewPartnerProject
                      inputs={fileInputs}
                      title="Add Partner Project File"
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:fileId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditFaq title="Edit Partner project File" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="profile">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <Profile />
                  </RequireAuth>
                }
              />
              <Route path=":projectId" element={<SingleProject />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={projectInputs} title="Add Project" />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:projectId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditProject title="Edit Project" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="sector">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":sectorId" element={<SingleSector />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={sectorInputs} title="Add Sector" />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:mdaId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditMda title="Edit M.D.A" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="mdas">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":mdaId" element={<SingleMda />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={mdaInputs} title="Add M.D.A" />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:mdaId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditMda title="Edit M.D.A" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="budget">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":budgetId" element={<SingleBudget />} />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    {/* <NewBudget inputs={budgetInputs} title="Add budget" /> */}
                    <BudgetModal />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="budgetPerformance">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route
                path="report"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <ReportList />
                  </RequireAuth>
                }
              />
              {/* <Route path=":budgetId" element={<SingleMda />} /> */}
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <Modal />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="report">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":reportId" element={<SingleReport />} />
            </Route>
            <Route path="suggestion">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":suggestionId" element={<SingleSuggestion />} />
            </Route>
            <Route path="newsComment">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <Home />
                  </RequireAuth>
                }
              />
              <Route path=":commentId" element={<SingleComment />} />
            </Route>
            <Route path="admins">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <New inputs={userInputs} title="Add New Admin User" />
                  </RequireAuth>
                }
              />
              <Route
                path="edit/:userId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <EditUser title="Edit Admin User" />
                  </RequireAuth>
                }
              />
              <Route
                path="changePassword"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <ChangePassword title="Change Password" />
                  </RequireAuth>
                }
              />
              <Route
                path="resetPassword/:userId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <ResetPassword title="Reset Password" />
                  </RequireAuth>
                }
              />
              <Route
                path=":accountId"
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <SingleAccount />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="newsletter">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="contact">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <List />
                  </RequireAuth>
                }
              />
              <Route path=":contactId" element={<SingleContact />} />
            </Route>
            <Route path="menus">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <Menu />
                  </RequireAuth>
                }
              />
              <Route path=":slug" element={<PageView />} />
            </Route>
            <Route path="pages">
              <Route
                index
                element={
                  <RequireAuth redirectTo="/unauthenticated">
                    <Pages />
                  </RequireAuth>
                }
              />
              <Route path=":slug" element={<PageView />} />
            </Route>
            <Route path="logout">
              <Route index element={<Login />} />
            </Route>
          </Route>
          <Route path="unauthenticated" element={<Unauthorized />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
