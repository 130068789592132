import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import StoreIcon from "@mui/icons-material/Store";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import BookIcon from "@mui/icons-material/Book";
import MoneyIcon from "@mui/icons-material/Money";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/apiCalls";

const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const reduxDispatch = useDispatch();
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <span className="logo">MEMIS ADMIN</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">Admin</p>
          <Link to="/admins" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <AccountTreeIcon className="icon" />
              <span>Accounts</span>
            </li>
          </Link>
          <Link to="/news" style={{ textDecoration: "none" }}>
            <li>
              <NewspaperIcon className="icon" />
              <span>News</span>
            </li>
          </Link>
          <Link to="/lgas" style={{ textDecoration: "none" }}>
            <li>
              <PsychologyOutlinedIcon className="icon" />
              <span>L.G.A</span>
            </li>
          </Link>
          <Link to="/faqs" style={{ textDecoration: "none" }}>
            <li>
              <PsychologyOutlinedIcon className="icon" />
              <span>FAQ</span>
            </li>
          </Link>
          <Link to="/sector" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" />
              <span>Sectors</span>
            </li>
          </Link>
          <Link to="/mdas" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" />
              <span>M.D.As</span>
            </li>
          </Link>
          {/* <li>
            <LocalShippingIcon className="icon" />
            <span>Delivery</span>
          </li> */}
          <p className="title">FEEDBACK</p>
          <Link to="/suggestion" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Suggestions</span>
            </li>
          </Link>
          <Link to="/report" style={{ textDecoration: "none" }}>
            <li>
              <FlagCircleIcon className="icon" />
              <span>Project Report</span>
            </li>
          </Link>
          <Link to="/newsletter" style={{ textDecoration: "none" }}>
            <li>
              <CardMembershipIcon className="icon" />
              <span>Subscribers</span>
            </li>
          </Link>
          <Link to="/contact" style={{ textDecoration: "none" }}>
            <li>
              <ContactMailIcon className="icon" />
              <span>Contacts</span>
            </li>
          </Link>
          <Link to="/callLog" style={{ textDecoration: "none" }}>
            <li>
              <BookIcon className="icon" />
              <span>Call Logs</span>
            </li>
          </Link>
          <p className="title">SERVICE</p>

          <li>
            <SettingsSystemDaydreamOutlinedIcon className="icon" />
            <span>Projects</span>
            <ExpandMoreIcon />
          </li>
          <ul className="subMenu">
            <Link to="/project" style={{ textDecoration: "none" }}>
              <li className="sub">State Projects</li>
            </Link>
            <Link to="/partnersProject" style={{ textDecoration: "none" }}>
              <li className="sub">Partners' Projects</li>
            </Link>
          </ul>

          <Link to="/budget" style={{ textDecoration: "none" }}>
            <li>
              <MoneyIcon className="icon" />
              <span>Budgets</span>
            </li>
          </Link>
          <Link to="/budgetPerformance" style={{ textDecoration: "none" }}>
            <li>
              <InsertChartIcon className="icon" />
              <span>Budget Performance Reports</span>
            </li>
          </Link>
          <Link to="/budgetFile" style={{ textDecoration: "none" }}>
            <li>
              <PsychologyOutlinedIcon className="icon" />
              <span>Budget File</span>
            </li>
          </Link>
          <Link to="/grant" style={{ textDecoration: "none" }}>
            <li>
              <PsychologyOutlinedIcon className="icon" />
              <span>Grants</span>
            </li>
          </Link>

          <Link to="/menus" style={{ textDecoration: "none" }}>
            <li>
              <NotificationsNoneIcon className="icon" />
              <span>Menu</span>
            </li>
          </Link>

          <Link to="/pages" style={{ textDecoration: "none" }}>
            <li>
              <AddLocationAltIcon className="icon" />
              <span>Pages</span>
            </li>
          </Link>
          {/* <li>
            <PsychologyOutlinedIcon className="icon" />
            <span>Polls</span>
          </li> */}
          {/* <p className="title">M & E</p>
          <li>
            <NotificationsNoneIcon className="icon" />
            <span>Releases</span>
          </li> */}
          <p className="title">USER</p>
          <Link to="/profile" style={{ textDecoration: "none" }}>
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Profile</span>
            </li>
          </Link>
          <Link
            to="/login"
            onClick={() => logout(reduxDispatch)}
            style={{ textDecoration: "none" }}
          >
            <li>
              <ExitToAppIcon className="icon" />
              <span>Logout</span>
            </li>
          </Link>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
