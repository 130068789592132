import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { deleteMenu } from "../../services/menuService";

const MenuList = ({ menus, setEditingMenu, refreshMenus }) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 20;

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this menu?"
    );
    if (!isConfirmed) return;
    await deleteMenu(id);
    refreshMenus();
  };

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="menu table">
        <TableHead className="table-header">
          <TableRow>
            <TableCell className="tableCell">Title</TableCell>
            <TableCell className="tableCell">Slug</TableCell>
            <TableCell className="tableCell">Type</TableCell>
            <TableCell className="tableCell">Parent Menu</TableCell>
            <TableCell className="tableCell">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {menus
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((menu) => (
              <TableRow key={menu._id}>
                <TableCell className="tableCell">{menu.title}</TableCell>
                <TableCell className="tableCell">
                  {menu.url.length > 30
                    ? `${menu.url.substring(0, 30)}...`
                    : menu.url}
                </TableCell>
                <TableCell className="tableCell">{menu.type}</TableCell>
                <TableCell className="tableCell">
                  {menu.parent_id ? menu.parent_id.title : "Root"}
                </TableCell>
                <TableCell className="tableCell cellAction">
                  <button
                    className="editButton"
                    onClick={() => setEditingMenu(menu)}
                  >
                    Edit
                  </button>
                  <button
                    className="deleteButton"
                    onClick={() => handleDelete(menu._id)}
                  >
                    Delete
                  </button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={menus.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(event, newPage) => setPage(newPage)}
        rowsPerPageOptions={[20]} // Fixed to 20 per page
      />
    </TableContainer>
  );
};

export default MenuList;
