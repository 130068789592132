import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
// import { publicRequest, userRequest } from "../../requestMethods"; // Import publicRequest
import "./menu.scss";
import MenuForm from "../../components/menu/MenuForm";
import MenuList from "../../components/menu/MenuList";
import { fetchMenus } from "../../services/menuService";

const Pages = () => {
  const [menus, setMenus] = useState([]);
  const [editingMenu, setEditingMenu] = useState(null);

  const refreshMenus = async () => setMenus(await fetchMenus());

  useEffect(() => {
    refreshMenus();
  }, []);

  return (
    <div className="pages">
      <Sidebar />
      <div className="pagesContainer">
        <Navbar />
        <h1 className="title">Manage Menu</h1>

        <div className="formContainer">
          <MenuForm
            editingMenu={editingMenu}
            setEditingMenu={setEditingMenu}
            refreshMenus={refreshMenus}
          />
        </div>

        <div className="tableContainer">
          <h2>Existing Menu</h2>
          <MenuList
            menus={menus}
            setEditingMenu={setEditingMenu}
            refreshMenus={refreshMenus}
          />
        </div>
      </div>
    </div>
  );
};

export default Pages;
