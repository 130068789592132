import "./singleAccount.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import List from "../../components/table/UserTable";
import { useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest, INDEX_URL } from "../../requestMethods";

const Single = () => {
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const [uStats, setUStats] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userRequest.get(`admins/${userId}`);
        setUStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, [userId]);

  // const handleDisableAccount = async () => {
  //   try {
  //     await userRequest.patch(`/admins/disable/${userId}`); // Change POST to PATCH
  //     alert("Account disabled successfully!");
  //     setUStats({ ...uStats, status: false }); // Update UI to reflect the change
  //   } catch (error) {
  //     console.log(error);
  //     alert("Failed to disable account.");
  //   }
  // };

  const handleToggleAccount = async () => {
    try {
      if (uStats.status) {
        await userRequest.patch(`/admins/disable/${userId}`);
        alert("Account disabled successfully!");
        setUStats({ ...uStats, status: false }); // Update UI
      } else {
        await userRequest.patch(`/admins/enable/${userId}`);
        alert("Account enabled successfully!");
        setUStats({ ...uStats, status: true }); // Update UI
      }
    } catch (error) {
      console.log(error);
      alert("Failed to update account status.");
    }
  };

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonu">
              <Link to={`/admins/edit/${userId}`}>Edit</Link>
            </div>
            <h1 className="titlen">Account Information</h1>
            <div className="item">
              <img
                src={
                  uStats.img
                    ? `${INDEX_URL}/${uStats.img}`
                    : require("../../assets/images/staff.png")
                }
                alt=""
                className="itemImgn"
              />
              <div className="details">
                <h1 className="itemTitle">{uStats.name}</h1>
                <div className="detailItem">
                  <span className="itemKeyu">Email:</span>
                  <span className="itemValue">{uStats.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKeyu">Phone:</span>
                  <span className="itemValue">{uStats.phone}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKeyu">M.D.A:</span>
                  <span className="itemValue">{uStats.mda}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKeyu">Department:</span>
                  <span className="itemValue">{uStats.department}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKeyu">Status:</span>
                  <span
                    className="itemValue"
                    style={{ color: uStats.status ? "green" : "red" }}
                  >
                    {uStats.status ? "Active" : "Disabled"}
                  </span>
                </div>
              </div>
            </div>
            <div className="actionButtons">
              <Link
                to={`/admins/resetPassword/${userId}`}
                className="resetButton"
              >
                Reset Password
              </Link>
              <button
                className={uStats.status ? "disableButton" : "enableButton"}
                onClick={handleToggleAccount}
              >
                {uStats.status ? "Disable Account" : "Enable Account"}
              </button>
            </div>
          </div>
        </div>
        <div className="bottom">
          <h1 className="title">Last Report</h1>
          <List />
        </div>
      </div>
    </div>
  );
};

export default Single;
