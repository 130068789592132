import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./pageView.scss";

const PageView = () => {
  const { slug } = useParams();
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const res = await axios.get(
          `http://localhost:5000/api/v1/pages/${slug}`
        );
        setPage(res.data);
      } catch (error) {
        console.error("Error fetching page:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPage();
  }, [slug]);

  return (
    <div className="pageView">
      <Sidebar />
      <div className="pageViewContainer">
        <Navbar />
        {loading ? (
          <p>Loading...</p>
        ) : page ? (
          <div className="pageContentss">
            <h1>{page.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
          </div>
        ) : (
          <p>Page not found</p>
        )}
      </div>
    </div>
  );
};

export default PageView;
