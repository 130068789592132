import axios from "axios";

const API_URL = "https://api.admethub.ng/api/v1/menus";

export const fetchMenus = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

export const createMenu = async (menuData) => {
  await axios.post(API_URL, menuData);
};

export const updateMenu = async (id, menuData) => {
  await axios.put(`${API_URL}/${id}`, menuData);
};

export const deleteMenu = async (id) => {
  await axios.delete(`${API_URL}/${id}`);
};
